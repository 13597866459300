<template>
  <vs-card class="p-5">
    <a @click="$router.go(-1)" class="block mb-5">
      <feather-icon
        icon="ArrowLeftIcon"
        class="cursor-pointer"
        style="vertical-align: bottom"
      />Back to new product
    </a>

    <label>Document Name</label>
    <vs-input
      v-model="documentName"
      class="mb-5 w-4/12"
      style="width: 100%"
      id="documentName"
      name="documentName"
      data-vv-validate-on="blur"
      data-vv-as="document name"
      v-validate="'required'"
    />

    <span
      class="text-danger text-sm mt-3 mb-6 block"
      v-show="errors.has('documentName')"
      >{{ errors.first("documentName") }}</span
    >

    <h3 class="text-base">Explicit Terms</h3>
    <vs-divider class="mt-2 mb-4"></vs-divider>
    <p class="text-sm mb-5">
      This content will appear explicitly on the payment page - customers will
      need to read and accept these terms before completing payment.
    </p>

    <p>Available linked content</p>

    <div class="available-content-grid w-6/12">
      <div
        class="available-content cursor-pointer"
        @click="addText('{{privacy}}', 'explicitTermsEditor')"
      >
        Privacy
      </div>
      <div
        class="available-content cursor-pointer"
        @click="addText('{{fullTerms}}', 'explicitTermsEditor')"
      >
        Full terms & conditions
      </div>

      <div
        class="available-content cursor-pointer"
        @click="addText('{{generalWebsiteTerms}}', 'explicitTermsEditor')"
      >
        General website terms
      </div>
    </div>

    <editor
      v-model="explicitTerms"
      id="explicitTerms"
      name="explicitTerms"
      data-vv-validate-on="blur"
      data-vv-as="explicit terms"
      v-validate="'required'"
      ref="explicitTermsEditor"
    ></editor>

    <span
      class="text-danger text-sm mt-3 mb-6 block"
      v-show="errors.has('explicitTerms')"
      >{{ errors.first("explicitTerms") }}</span
    >

    <h3 class="text-base mt-10">Full Document</h3>
    <vs-divider class="mt-2 mb-4"></vs-divider>
    <p class="text-sm mb-5">A link to this content will appear payment page.</p>
    <p>Available linked content</p>
    <div class="available-content-grid w-2/12">
      <div
        class="available-content cursor-pointer"
        @click="addText('{{privacy}}', 'fullDocumentEditor')"
      >
        Privacy
      </div>
      <div
        class="available-content cursor-pointer"
        @click="addText('{{generalWebsiteTerms}}', 'fullDocumentEditor')"
      >
        General website terms
      </div>
    </div>

    <editor
      v-model="fullDocument"
      id="fullDocument"
      name="fullDocument"
      data-vv-validate-on="blur"
      data-vv-as="full document"
      ref="fullDocumentEditor"
    ></editor>

    <span
      class="text-danger text-sm mt-3 mb-6 block"
      v-show="errors.has('fullDocument')"
      >{{ errors.first("fullDocument") }}</span
    >

    <leave-popup
      :popup-active="popupActive"
      @handleClick="popActions"
    ></leave-popup>

    <pop-up :errorsList="errors" form-name="ProductLegalEditorForm"></pop-up>

    <div class="flex justify-end items-center">
      <vs-button
        @click="addProductLegal()"
        style="margin-top: 2em"
        class="w-full sm:w-auto"
        :disabled="!validateForm"
        v-round
      >
        {{ this.$route.query.newVersion ? "Save new version" : "Create" }}
      </vs-button>

      <a
        @click="goToPayLater()"
        style="margin-top: 2em"
        class="w-full sm:w-auto ml-5 underline"
      >
        Cancel
      </a>
    </div>
  </vs-card>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import Editor from "../../QuillEditor.vue";
import { mapMutations, mapGetters ,mapState} from "vuex";
import _ from "lodash";

export default {
  components: {
    Editor,
    ValidationProvider,
    PopUp,
    LeavePopup,
  },
  data() {
    return {
      isButtonHidden: false,
      id: "",
      popupActive: false,
      isSaved: false,
      nextObj: "", // determines the next route,
      explicitTerms: "",
      fullDocument: "",
      documentName: "",
    };
  },
  methods: {
    ...mapMutations("paymentPlans", ["ADD_LEGALS", "DELETE_LEGALS"]),
    generateRandom() {
      let firstBits = Math.floor(1000 + Math.random() * 9000);
      let secondBits = Math.floor(1000 + Math.random() * 9000);
      return `LG-${firstBits}-${secondBits}`;
    },
    addText(val, editorRef) {
      this.$refs[editorRef].insertTextAtCursor(val);
    },
    popActions(event) {
      switch (event) {
        case "leave":
          this.nextObj();
          break;
        case "save":
          this.popupActive = false;
          this.addProductLegal();
          break;
        case "cancel":
          this.popupActive = false;
          break;
        default:
          this.popupActive = false;
      }
    },

    goToPayLater() {
      let query = {};
      query.productType = this.$route.query.productType;
      query.legal = true;

      if (this.$route.query.id) {
        query.id = this.$route.query.id;
      }

      this.$router.push({ name: "pay-later-product", query: query });
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    addProductLegal() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          const legals = this.getCurrentPaymentPlan.legals;
          const legal = {
            documentName: this.documentName,
            explicitTerms: this.explicitTerms,
            fullDocument: this.fullDocument,
            type: "T&C",
            user: this.$route.query.type,
            _id: this.generateRandom(),
            createdAt: new Date().toISOString(),
          };

          // legals with type
          const legalWithType = legals.filter(
            (el) => el.user === this.$route.query.type && el.type == "T&C"
          );

          // increase version by 1 or set initial to 1
          legal.version = legalWithType.length === 0 ? 1 : Math.max(...legalWithType.map((o) => o.version), 0) + 1;

          this.ADD_LEGALS(legal);
          this.isSaved = true;
          this.goToPayLater();
        }
      }).catch((ex) => {
        if (ex.name === "QuotaExceededError") {
          this.DELETE_LEGALS()
          this.showMessage("Error","Either the characters of the content or the size of the image file is too big", "danger", "icon-times");
        }
      });
    },
  },

  beforeRouteLeave(to, from, next) {
    //check here
    if (this.isFormDirty && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
    } else {
      next();
    }
  },
  beforeMount() {
    // edit case

    if (this.$route.query.newVersion) {
      const legals = this.getCurrentPaymentPlan.legals.filter(
        (el) => el.user === this.$route.query.type && el.type == "T&C"
      );
      const latest = _.sortBy(legals, ["createdAt"]).reverse()[0];
      this.documentName = latest.documentName;
      this.explicitTerms = latest.explicitTerms;
      this.fullDocument = latest.fullDocument;
    }
  },
  computed: {
    ...mapGetters("paymentPlans", ["getCurrentPaymentPlan"]),
    ...mapState("paymentPlans", ["currentPlan"]),
  },
};
</script>

